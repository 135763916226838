export default {
  GET_DEVICES_REQUEST: "GET_DEVICES_REQUEST",
  GET_DEVICES_SUCCESS: "GET_DEVICES_SUCCESS",
  GET_DEVICES_FAIL: "GET_DEVICES_FAIL",

  ACCEPT_DEVICE_CONNECTION_REQUEST: "ACCEPT_DEVICE_CONNECTION_REQUEST",
  ACCEPT_DEVICE_CONNECTION_SUCCESS: "ACCEPT_DEVICE_CONNECTION_SUCCESS",
  ACCEPT_DEVICE_CONNECTION_FAIL: "ACCEPT_DEVICE_CONNECTION_FAIL",

  REMOVE_DEVICE_CONNECTION_REQUEST: "REMOVE_DEVICE_CONNECTION_REQUEST",
  REMOVE_DEVICE_CONNECTION_SUCCESS: "REMOVE_DEVICE_CONNECTION_SUCCESS",
  REMOVE_DEVICE_CONNECTION_FAIL: "REMOVE_DEVICE_CONNECTION_FAIL",
};
