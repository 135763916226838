import { combineReducers } from "redux";
import { VisitorTypes } from "../../action_types";

import { handleRequest, initialRequestState } from "./helpers";

function getVisitors(state = initialRequestState(), action) {
  return handleRequest(
    VisitorTypes.GET_VISITORS_REQUEST,
    VisitorTypes.GET_VISITORS_SUCCESS,
    VisitorTypes.GET_VISITORS_FAIL,
    state,
    action
  );
}

function getVisitor(state = initialRequestState(), action) {
  return handleRequest(
    VisitorTypes.GET_VISITOR_REQUEST,
    VisitorTypes.GET_VISITOR_SUCCESS,
    VisitorTypes.GET_VISITOR_FAIL,
    state,
    action
  );
}

function createVisitor(state = initialRequestState(), action) {
  return handleRequest(
    VisitorTypes.CREATE_VISITOR_REQUEST,
    VisitorTypes.CREATE_VISITOR_SUCCESS,
    VisitorTypes.CREATE_VISITOR_FAIL,
    state,
    action
  );
}

function updateVisitor(state = initialRequestState(), action) {
  return handleRequest(
    VisitorTypes.UPDATE_VISITOR_REQUEST,
    VisitorTypes.UPDATE_VISITOR_SUCCESS,
    VisitorTypes.UPDATE_VISITOR_FAIL,
    state,
    action
  );
}

function deleteVisitor(state = initialRequestState(), action) {
  return handleRequest(
    VisitorTypes.DELETE_VISITOR_REQUEST,
    VisitorTypes.DELETE_VISITOR_SUCCESS,
    VisitorTypes.DELETE_VISITOR_FAIL,
    state,
    action
  );
}

export default combineReducers({
  getVisitors,
  getVisitor,
  createVisitor,
  updateVisitor,
  deleteVisitor,
});
