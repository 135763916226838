export default {
  GET_REGISTRATIONS_REQUEST: 'GET_REGISTRATIONS_REQUEST',
  GET_REGISTRATIONS_SUCCESS: 'GET_REGISTRATIONS_SUCCESS',
  GET_REGISTRATIONS_FAIL: 'GET_REGISTRATIONS_FAIL',

  GET_REGISTRATION_REQUEST: 'GET_REGISTRATION_REQUEST',
  GET_REGISTRATION_SUCCESS: 'GET_REGISTRATION_SUCCESS',
  GET_REGISTRATION_FAIL: 'GET_REGISTRATION_FAIL',

  APPROVE_REGISTRATION_REQUEST: 'APPROVE_REGISTRATION_REQUEST',
  APPROVE_REGISTRATION_SUCCESS: 'APPROVE_REGISTRATION_SUCCESS',
  APPROVE_REGISTRATION_FAIL: 'APPROVE_REGISTRATION_FAIL',

  DECLINE_REGISTRATION_REQUEST: 'DECLINE_REGISTRATION_REQUEST',
  DECLINE_REGISTRATION_SUCCESS: 'DECLINE_REGISTRATION_SUCCESS',
  DECLINE_REGISTRATION_FAIL: 'DECLINE_REGISTRATION_FAIL',

  ADD_REGISTRATION_REQUEST: 'ADD_REGISTRATION_REQUEST',
  ADD_REGISTRATION_SUCCESS: 'ADD_REGISTRATION_SUCCESS',
  ADD_REGISTRATION_FAIL: 'ADD_REGISTRATION_FAIL',

  UPDATE_REGISTRATION_REQUEST: 'UPDATE_REGISTRATION_REQUEST',
  UPDATE_REGISTRATION_SUCCESS: 'UPDATE_REGISTRATION_SUCCESS',
  UPDATE_REGISTRATION_FAIL: 'UPDATE_REGISTRATION_FAIL',

  UPDATE_BUILDING_REGISTER_REQUEST: 'UPDATE_BUILDING_REGISTER_REQUEST',
  UPDATE_BUILDING_REGISTER_SUCCESS: 'UPDATE_BUILDING_REGISTER_SUCCESS',
  UPDATE_BUILDING_REGISTER_FAIL: 'UPDATE_BUILDING_REGISTER_FAIL',

  UPDATE_ORG_REGISTER_REQUEST: 'UPDATE_ORG_REGISTER_REQUEST',
  UPDATE_ORG_REGISTER_SUCCESS: 'UPDATE_ORG_REGISTER_SUCCESS',
  UPDATE_ORG_REGISTER_FAIL: 'UPDATE_ORG_REGISTER_FAIL',
};
