import axios from 'axios';
/* eslint-disable no-throw-literal */

export default class Client {
  constructor() {
    this.url = '';
    this.urlVersion = '';
    this.token = '';
  }

  paramsHelper = Obj => {
    const paramsStr = Object.keys(Obj).reduce((str, key) => {
      const paramStr = Obj[key] ? `&${key}=${Obj[key]}` : '';
      return str + paramStr;
    }, '');

    return paramsStr;
  };

  removeFalsy = obj => {
    let newObj = {};
    Object.keys(obj).forEach(prop => {
      if (!!obj[prop]) {
        newObj[prop] = obj[prop];
      }
    });
    return newObj;
  };

  removeEmptyParams = p => {
    const params = { ...p };
    for (const key of Object.keys(params)) {
      if (!params[key]) {
        delete params[key];
      }
    }
    return params;
  };

  getUrl = () => {
    return this.url;
  };

  setUrl = url => {
    this.url = url;
  };

  getToken = () => {
    return this.token;
  };

  setToken = token => {
    this.token = token;
  };

  getBaseRoute = () => {
    return `${this.url}${this.urlVersion}`;
  };

  getUserRoute = building_id => {
    return `${this.getBaseRoute()}/buildings/${building_id}/users`;
  };

  getBuildingRoute = () => {
    return `${this.getBaseRoute()}/buildings`;
  };

  getVisitorRoute = building_id => {
    return `${this.getBaseRoute()}/buildings/${building_id}/visitors`;
  };

  getQuestionRoute = building_id => {
    return `${this.getBaseRoute()}/buildings/${building_id}/questions`;
  };

  getCheckinRoute = building_id => {
    return `${this.getBaseRoute()}/buildings/${building_id}/visitor_entries`;
  };

  getDeviceRoute = building_id => {
    return `${this.getBaseRoute()}/buildings/${building_id}/devices`;
  };

  getInviteRoute = building_id => {
    return `${this.getBaseRoute()}/buildings/${building_id}/invites`;
  };

  getCompanyRoute = building_id => {
    return `${this.getBaseRoute()}/buildings/${building_id}/companies`;
  };

  getBlockRoute = building_id => {
    return `${this.getBaseRoute()}/buildings/${building_id}/blocklist_rules`;
  };

  getAuthRoute = () => {
    return `${this.getBaseRoute()}/user`;
  };

  // auth
  signup = token => {
    return this.doFetch('post', `${this.getUserRoute()}`, { token });
  };

  checkLogin = (email, password) => {
    let formData = new FormData();
    formData.append('email', email);
    formData.append('password', password);
    return this.doFetchWithoutToken('post', `${this.getBaseRoute()}/check_login`, formData);
  };

  login = (email, password) => {
    let formData = new FormData();
    formData.append('email', email);
    formData.append('password', password);
    // formData.append("otp", otp);
    return this.doFetchWithoutToken('post', `${this.getBaseRoute()}/login`, formData);
  };

  logout = () => {
    return this.doFetch('get', `${this.getBaseRoute()}/logout`);
  };

  getMe = () => {
    return this.doFetch('get', `${this.getAuthRoute()}`);
  };

  checkEmail = email => {
    return this.doFetchWithoutToken('get', `${this.getBaseRoute()}/users/check-email-existed?email=${email}`);
  };

  register = ({
    full_name,
    email,
    phone,
    department,
    invitation_code,
    org_name,
    address,
    tax_code,
    website,
    field,
    employee_size,
    time_zone,
    building_json,
  }) => {
    return this.doFetchWithoutToken('post', `${this.getBaseRoute()}/register`, {
      full_name,
      email,
      phone,
      department,
      invitation_code,
      org_name,
      address,
      tax_code,
      website,
      field,
      employee_size,
      time_zone,
      building_json,
    });
  };

  // checkin

  getCheckins = ({ page = 1, size = 10, start, end, search_info, building_id, search_access_card_id, checked_out }) => {
    const paramsStr = this.paramsHelper({
      start,
      end,
      search_info,
      search_access_card_id,
      checked_out,
    });
    return this.doFetch('get', `${this.getCheckinRoute(building_id)}/search?page=${page}&size=${size}${paramsStr}`);
  };

  getCheckin = (id, building_id) => {
    return this.doFetch('get', `${this.getCheckinRoute(building_id)}/${id}`);
  };

  createCheckin = ({
    name,
    access_card_id,
    card_id,
    address,
    phone,
    born,
    gender,
    nationality,
    note,
    email,
    company_id,
    floor,
    city,
    district,
    ward,
    check_in_time,
    building_id,
    entry_selfie_photo,
    entry_id_photo_ids,
  }) => {
    const data = this.removeFalsy({
      name,
      access_card_id,
      card_id,
      address,
      phone,
      born,
      gender,
      note,
      email,
      company_id,
      nationality,
      city,
      district,
      ward,
      floor,
      check_in_time,
      entry_selfie_photo,
      entry_id_photo_ids,
    });
    let formData = new FormData();
    Object.keys(data).forEach(key => {
      if (key === 'entry_id_photo_ids') {
        data['entry_id_photo_ids'].forEach(id => {
          formData.append('entry_id_photo_ids', id);
        });
      } else {
        formData.append(key, data[key]);
      }
    });
    return this.doFetch('post', `${this.getBaseRoute()}/buildings/${building_id}/checkin_manual`, formData);
  };

  updateCheckin = ({ id, skipped_id_scan, building_id, ...rest }) => {
    const data = this.removeFalsy({
      ...rest,
    });
    return this.doFetch('put', `${this.getCheckinRoute(building_id)}/${id}`, {
      skipped_id_scan,
      ...data,
    });
  };

  ocr = (file, building_id) => {
    let formData = new FormData();
    formData.append('mattruoc', file);
    return this.doFetch('post', `${this.getCheckinRoute(building_id)}/read`, formData);
  };

  markLostAccessCard = (id, building_id) => {
    return this.doFetch('put', `${this.getCheckinRoute(building_id)}/${id}`, {
      lost_access_card: true,
    });
  };

  updateCheckinAndVisitor = (form1, form2, building_id) => {
    return Promise.all([
      this.doFetch('put', `${this.getCheckinRoute(building_id)}/${form1.id}`, form1),
      this.doFetch('put', `${this.getVisitorRoute(building_id)}/${form2.id}`, form2),
    ]);
  };

  deleteCheckin = (id, building_id) => {
    return this.doFetch('delete', `${this.getCheckinRoute(building_id)}/${id}`);
  };

  checkout = (id, building_id) => {
    return this.doFetch('put', `${this.getCheckinRoute(building_id)}/${id}/check_out`);
  };

  declineCheckin = ({ id, decline_approve_reason, building_id }) => {
    return this.doFetch('put', `${this.getCheckinRoute(building_id)}/${id}/decline_approve`, {
      id,
      decline_approve_reason,
    });
  };

  exportCheckinsExcel = ({ start, end, company_id, building_id }) => {
    const paramsStr = this.paramsHelper({ company_id });
    return this.downloadFile(
      `${this.getCheckinRoute(building_id)}/search?start=${start}&end=${end}${paramsStr}&export=true`,
      'checkins.xlsx'
    );
  };

  getCheckinHistory = ({ page = 1, size = 10, visitor_id, building_id }) => {
    return this.doFetch(
      'get',
      `${this.getCheckinRoute(building_id)}/search?page=${page}&size=${size}&visitor_id=${visitor_id}`
    );
  };

  // visitor

  getVisitors = ({ page = 1, size = 10, groups }) => {
    const data = this.removeFalsy({
      groups,
    });
    return this.doFetch('post', `${this.getVisitorRoute()}`, data);
  };

  getVisitor = (id, building_id) => {
    return this.doFetch('get', `${this.getVisitorRoute(building_id)}/${id}`);
  };

  createVisitor = ({ timezone, company_id, email, phone, name, groups }) => {
    const data = this.removeFalsy({
      timezone,
      company_id,
      email,
      phone,
      name,
      groups,
    });
    return this.doFetch('post', `${this.getVisitorRoute()}/create`, data);
  };

  updateVisitor = ({
    id,
    name,
    card_id,
    access_card_id,
    born,
    gender,
    address,
    nationality,
    hometown,
    building_id,
  }) => {
    const data = this.removeFalsy({
      name,
      card_id,
      access_card_id,
      born,
      gender,
      address,
      nationality,
      hometown,
    });
    return this.doFetch('put', `${this.getVisitorRoute(building_id)}/${id}`, data);
  };

  deleteVisitor = id => {
    return this.doFetch('delete', `${this.getVisitorRoute()}/${id}`);
  };

  // user

  getUsers = ({ page = 1, size = 10, user_roles, name, company_id, building_id }) => {
    const data = this.removeFalsy({
      user_roles,
      name,
      company_id,
    });
    return this.doFetch('post', `${this.getUserRoute(building_id)}?page=${page}&size=${size}`, data); // group: building_admin, kiosk, company_employee, front_desk
  };

  getOrgUsers = ({ page = 1, size = 10, user_roles, name, company_id }) => {
    const data = this.removeFalsy({
      user_roles,
      name,
      company_id,
    });
    return this.doFetch('post', `${this.getBaseRoute()}/users?page=${page}&size=${size}`, data); // group: building_admin, kiosk, company_employee, front_desk
  };

  getUser = (id, building_id) => {
    return this.doFetch('get', `${this.getUserRoute(building_id)}/${id}`);
  };

  getOrgUser = id => {
    return this.doFetch('get', `${this.getBaseRoute()}/users/${id}`);
  };

  createUser = ({ timezone, company_id, email, phone, name, building_roles, building_id }) => {
    const data = this.removeFalsy({
      timezone,
      company_id,
      email,
      phone,
      name,
      building_roles,
    });
    return this.doFetch('post', `${this.getUserRoute(building_id)}/create`, data);
  };

  updateUser = ({ id, company_id, email, phone, name, building_roles, building_id }) => {
    const data = this.removeFalsy({
      company_id,
      email,
      phone,
      name,
      building_roles,
    });
    return this.doFetch('put', `${this.getUserRoute(building_id)}/${id}`, data);
  };

  updateOrgUser = ({ id, company_id, email, phone, name, building_roles, building_id }) => {
    const data = this.removeFalsy({
      company_id,
      email,
      phone,
      name,
      building_roles,
      building_id,
    });
    return this.doFetch('put', `${this.getBaseRoute()}/users/${id}`, data);
  };

  deleteUser = (id, building_id) => {
    return this.doFetch('delete', `${this.getUserRoute(building_id)}/${id}`);
  };

  importStaffsExcel = (file, building_id) => {
    let formData = new FormData();
    formData.append('file', file);
    return this.doFetch('post', `${this.getBaseRoute()}/buildings/${building_id}/staffs/import`, formData);
  };
  exportStaffsExcel = building_id => {
    return this.downloadFile(`${this.getBaseRoute()}/buildings/${building_id}/staffs/export`, 'staffs.xlsx');
  };

  changePassword = (old_password, new_password) => {
    let formData = new FormData();
    formData.append('old_password', old_password);
    formData.append('new_password', new_password);
    formData.append('web', true);
    return this.doFetch('put', `${this.getBaseRoute()}/users/change_password`, formData);
  };

  changePasswordApp = new_password => {
    let formData = new FormData();
    formData.append('new_password', new_password);
    return this.doFetch('put', `${this.getBaseRoute()}/users/change_password`, formData);
  };

  resetPassword = email => {
    let formData = new FormData();
    formData.append('email', email);
    return this.doFetchWithoutToken('post', `${this.getBaseRoute()}/password_reset`, formData);
  };

  resetPasswordConfirm = (password, token) => {
    return this.doFetchWithoutToken('post', `${this.getBaseRoute()}/password_reset_confirm`, { password, token });
  };

  createUserByInvitation = ({ token, password, timezone }) => {
    return this.doFetchWithoutToken('post', `${this.getBaseRoute()}/users/create`, { token, password, timezone });
  };

  // building

  getBuildingsByOrgId = ({ org_id, limit, offset }) => {
    return this.doFetch(
      'get',
      `${this.getBaseRoute()}/super-admin/buildings?org__id=${org_id}&&limit=${limit}&&offset=${offset}`
    );
  };

  getBuildings = () => {
    return this.doFetch('get', `${this.getBuildingRoute()}`);
  };

  getBuilding = id => {
    return this.doFetch('get', `${this.getBuildingRoute()}/${id}`);
  };

  createBuilding = ({ time_zone, name, address, city, country, geolocation, floors, theme_color, district }) => {
    const data = this.removeFalsy({
      time_zone,
      name,
      address,
      city,
      country,
      geolocation,
      floors,
      theme_color,
      district,
    });
    return this.doFetch('post', `${this.getBaseRoute()}/buildings/create`, data);
  };

  updateBuilding = (
    {
      id,
      name,
      address,
      city,
      country,
      geolocation,
      floors,
      theme_color,
      time_zone,
      district,
      skip_survey,
      survey_questions,
      survey_type,
    },
    skip_remove_falsy
  ) => {
    const data = skip_remove_falsy
      ? {
          name,
          address,
          city,
          country,
          geolocation,
          floors,
          theme_color,
          time_zone,
          district,
          skip_survey,
          survey_questions,
          survey_type,
        }
      : this.removeFalsy({
          name,
          address,
          city,
          country,
          geolocation,
          floors,
          theme_color,
          time_zone,
          district,
          skip_survey,
          survey_questions,
          survey_type,
        });
    return this.doFetch('put', `${this.getBuildingRoute()}/${id}`, data);
  };

  deactivateBuilding = id => {
    return this.doFetch('put', `${this.getBuildingRoute()}/${id}/deactive`);
  };

  activateBuilding = id => {
    return this.doFetch('put', `${this.getBuildingRoute()}/${id}/active`);
  };

  uploadLogo = (id, image) => {
    return this.uploadImage(`${this.getBuildingRoute()}/${id}/profile_image`, image);
  };

  getLogo = id => {
    return this.getImage(`${this.getBuildingRoute()}/${id}/profile_image?t=${new Date().getTime()}`);
  };
  // question

  getQuestions = building_id => {
    return this.doFetch('post', `${this.getBaseRoute()}/buildings/${building_id}/survey`);
  };

  createQuestion = ({ vietnamese, english, building_id, qr_image, question_type }) => {
    const data = this.removeFalsy({ vietnamese, english, qr_image, question_type });
    let formData = new FormData();
    Object.keys(data).forEach(key => {
      formData.append(key, data[key]);
    });
    return this.doFetch('post', `${this.getQuestionRoute(building_id)}`, formData);
  };

  updateQuestion = ({ id, vietnamese, english, qr_image, building_id }) => {
    let formData = new FormData();
    formData.append('vietnamese', vietnamese);
    formData.append('english', english);
    if (qr_image) {
      formData.append('qr_image', qr_image);
    }
    return this.doFetch('put', `${this.getQuestionRoute(building_id)}/${id}`, formData);
  };

  deleteQuestion = (id, building_id) => {
    return this.doFetch('delete', `${this.getQuestionRoute(building_id)}/${id}`);
  };

  // company

  getCompanies = ({ floor, name, building_id }) => {
    return this.doFetch2({ method: 'get', url: `${this.getCompanyRoute(building_id)}`, params: { floor, name } });
  };
  getCompany = (id, building_id) => {
    return this.doFetch('get', `${this.getCompanyRoute(building_id)}/${id}`);
  };
  createCompany = ({ name, display_name, floors, building_id }) => {
    return this.doFetch('post', `${this.getCompanyRoute(building_id)}`, {
      name,
      display_name,
      floors,
    });
  };
  updateCompany = ({ id, building_id, ...info }) => {
    return this.doFetch('put', `${this.getCompanyRoute(building_id)}/${id}`, info);
  };
  deleteCompany = (id, building_id) => {
    return this.doFetch('delete', `${this.getCompanyRoute(building_id)}/${id}`);
  };
  importCompaniesExcel = (file, building_id) => {
    let formData = new FormData();
    formData.append('file', file);
    return this.doFetch('post', `${this.getCompanyRoute(building_id)}/import`, formData);
  };
  exportCompaniesExcel = building_id => {
    return this.downloadFile(`${this.getCompanyRoute(building_id)}/export`, 'companies.xlsx');
  };
  registerContact = info => {
    return this.doFetch('post', `${this.getBaseRoute()}/register`, info);
  };

  // block

  getBlocks = ({ page = 1, size = 10, search_info, building_id }) => {
    return this.doFetch2({
      method: 'get',
      url: `${this.getBlockRoute(building_id)}`,
      params: { page, size, search_info },
    });
  };
  getBlock = (id, building_id) => {
    return this.doFetch('get', `${this.getBlockRoute(building_id)}/${id}`);
  };
  createBlock = ({ building_id, ...info }) => {
    const data = this.removeFalsy(info);
    let formData = new FormData();
    Object.keys(data).forEach(key => {
      formData.append(key, data[key]);
    });
    return this.doFetch('post', `${this.getBlockRoute(building_id)}`, formData);
  };
  updateBlock = ({ id, building_id, ...info }) => {
    const data = this.removeFalsy(info);
    let formData = new FormData();
    Object.keys(data).forEach(key => {
      formData.append(key, data[key]);
    });
    return this.doFetch('put', `${this.getBlockRoute(building_id)}/${id}`, formData);
  };
  deleteBlock = (id, building_id) => {
    return this.doFetch('delete', `${this.getBlockRoute(building_id)}/${id}`);
  };
  importBlocksExcel = (file, building_id) => {
    let formData = new FormData();
    formData.append('file', file);
    return this.doFetch('post', `${this.getBlockRoute(building_id)}/import`, formData);
  };
  exportBlocksExcel = building_id => {
    return this.downloadFile(`${this.getBlockRoute(building_id)}/export`, 'companies.xlsx');
  };

  // device

  getDevices = building_id => {
    return this.doFetch('post', `${this.getDeviceRoute(building_id)}`);
  };

  acceptDeviceConnection = (code_number, building_id) => {
    return this.doFetch('post', `${this.getDeviceRoute(building_id)}/accept_device`, {
      code_number,
    });
  };

  removeDeviceConnection = (id, building_id) => {
    return this.doFetch('put', `${this.getDeviceRoute(building_id)}/${id}/remove_connection`);
  };

  // invites

  getInvites = ({
    page = 1,
    size = 10,
    company_id,
    confirmed_status,
    search_info,
    start,
    end,
    my_invite,
    building_id,
  }) => {
    const data = this.removeFalsy({
      company_id,
      confirmed_status,
      search_info,
      start,
      end,
      my_invite,
    });
    return this.doFetch('post', `${this.getInviteRoute(building_id)}/search?page=${page}&size=${size}`, data);
  };

  getInvite = (id, building_id) => {
    return this.doFetch('get', `${this.getInviteRoute(building_id)}/${id}`);
  };

  createInvite = ({ company_id, floor, invitee_name, schedule_checkin_time, email, phone, note, building_id }) => {
    const data = this.removeFalsy({
      company_id,
      floor,
      invitee_name,
      schedule_checkin_time,
      email,
      phone,
      note,
    });
    return this.doFetch('post', `${this.getBaseRoute()}/buildings/${building_id}/pre-register`, data);
  };

  updateInvite = ({ id, building_id, ...rest }) => {
    const data = this.removeFalsy({
      ...rest,
    });
    return this.doFetch('put', `${this.getInviteRoute(building_id)}/${id}`, data);
  };

  deleteInvite = (id, building_id) => {
    return this.doFetch('delete', `${this.getInviteRoute(building_id)}/${id}`);
  };

  confirmInvite = ({ id, confirm, decline_reason, building_id }) => {
    return this.doFetch('put', `${this.getInviteRoute(building_id)}/confirm`, {
      invite_ids: [id],
      confirm,
      decline_reason,
    });
  };

  acceptInvites = (invite_ids, building_id) => {
    return this.doFetch('put', `${this.getInviteRoute(building_id)}/confirm`, {
      confirm: true,
      invite_ids,
    });
  };

  declineInvites = (invite_ids, building_id) => {
    return this.doFetch('put', `${this.getInviteRoute(building_id)}/confirm`, {
      confirm: false,
      invite_ids,
    });
  };

  // billing

  getBilling = () => {
    return this.doFetch('get', `${this.getBaseRoute()}/billing`);
  };

  // statistical

  getStatisticalThisMonth = building_id => {
    return this.doFetch('get', `${this.getCheckinRoute(building_id)}/analytics/this_month`);
  };

  getStatisticalCheckinByCompanies = (start_time, end_time, building_id) => {
    return this.doFetch(
      'get',
      `${this.getCheckinRoute(building_id)}/analytics/checkin_companies?start_time=${start_time}&end_time=${end_time}`
    );
  };

  getStatisticalCheckinByType = (start_time, end_time, building_id) => {
    return this.doFetch(
      'get',
      `${this.getCheckinRoute(building_id)}/analytics/checkin_by_type?start_time=${start_time}&end_time=${end_time}`
    );
  };

  getStatisticalCheckinByDayInWeek = (start_time, end_time, building_id) => {
    return this.doFetch(
      'get',
      `${this.getCheckinRoute(
        building_id
      )}/analytics/checkin_each_week_day?start_time=${start_time}&end_time=${end_time}`
    );
  };

  getStatisticalCheckinEachDay = (start_time, end_time, building_id) => {
    return this.doFetch(
      'get',
      `${this.getCheckinRoute(building_id)}/analytics/checkin_each_day?start_time=${start_time}&end_time=${end_time}`
    );
  };

  // registration

  getRegistrations = ({ limit, offset, search, start, end, status }) => {
    return this.doFetch2({
      method: 'get',
      url: `${this.getBaseRoute()}/registrations`,
      params: {
        limit,
        offset,
        search,
        start,
        end,
        org__status: status,
      },
    });
  };

  getRegistration = id => {
    return this.doFetch('get', `${this.getBaseRoute()}/registrations/${id}`);
  };

  approveRegistration = (id, accept) => {
    return this.doFetch('put', `${this.getBaseRoute()}/org-members/${id}/approve`, { accept });
  };

  createRegistration = body => {
    return this.doFetch('post', `${this.getBaseRoute()}/super-admin/organizations`, body);
  };

  updateRegistration = (registrationId, body) => {
    return this.doFetch('put', `${this.getBaseRoute()}/registrations/${registrationId}`, body);
  };

  updateOrgRegister = (orgId, body) => {
    return this.doFetch('put', `${this.getBaseRoute()}/super-admin/organizations/${orgId}`, body);
  };
  updateBuildingRegister = (buildingId, body) => {
    return this.doFetch('put', `${this.getBaseRoute()}/buildings/${buildingId}`, body);
  };
  // image

  getIdImage = (id, building_id) => {
    return this.getImage(`${this.getBaseRoute()}/buildings/${building_id}/images/id_photo/${id}`);
  };

  getSelfieImage = (id, building_id) => {
    return this.getImage(`${this.getBaseRoute()}/buildings/${building_id}/images/selfie_photo/${id}`);
  };

  getBlockImage = (id, building_id) => {
    return this.getImage(`${this.getBaseRoute()}/buildings/${building_id}/images/image_photo/${id}`);
  };

  getImage = url => {
    return axios({
      method: 'get',
      url,
      headers: {
        Authorization: 'Bearer ' + this.token,
      },
      responseType: 'blob',
    }).then(res => {
      if (res.data?.type.indexOf('image') === -1) {
        return null;
      }
      return new Blob([res.data]);
    });
  };

  uploadImage = (url, image) => {
    const formData = new FormData();
    formData.append('avatar', image);

    return axios({
      method: 'put',
      url,
      data: formData,
      headers: {
        Authorization: 'Bearer ' + this.token,
        'Content-Type': 'multipart/form-data',
      },
      responseType: 'blob',
    }).then(res => {
      return new Blob([res.data]);
    });
  };

  doFetch = (method, url, data = {}) => {
    return axios({
      method,
      url,
      data,
      headers: {
        Authorization: 'Bearer ' + this.token,
      },
    }).then(res => {
      return res.data;
    });
  };
  doFetch2 = ({ method, url, params = {}, data = {} }) => {
    const newParams = this.removeEmptyParams(params);
    return axios({
      method,
      url,
      params: newParams,
      data,
      headers: {
        Authorization: 'Bearer ' + this.token,
      },
    }).then(res => {
      return res.data;
    });
  };
  doFetchWithoutToken = (method, url, data = {}) => {
    return axios({
      method,
      url,
      data,
    }).then(res => {
      return res.data;
    });
  };
  downloadFile = (url, filename) => {
    return axios({
      method: 'get',
      url,
      headers: {
        Authorization: 'Bearer ' + this.token,
      },
      responseType: 'blob',
    })
      .then(res => {
        // 2. Create blob link to download
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', filename);
        // 3. Append to html page
        document.body.appendChild(link);
        // 4. Force download
        link.click();
        // 5. Clean up and remove the link
        link.parentNode.removeChild(link);
      })
      .catch(error => {
        if (error.response) {
          return error.response.data;
        }
        if (error.request) {
          console.log(error.request);
        } else {
          console.log('Error', error.message);
        }
      });
  };
}
