import { combineReducers } from 'redux';
import { RegistrationTypes } from '../../action_types';
import { arrayToObject, removeItem, insertItem, updateObjectInArray } from '@app/utils/helpers';

function all(state = {}, action) {
  switch (action.type) {
    case RegistrationTypes.GET_REGISTRATIONS_SUCCESS:
      return { ...state, ...arrayToObject(action.data) };
    case RegistrationTypes.GET_REGISTRATION_SUCCESS:
    case RegistrationTypes.APPROVE_REGISTRATION_SUCCESS:
    case RegistrationTypes.DECLINE_REGISTRATION_SUCCESS:
    case RegistrationTypes.UPDATE_REGISTRATION_SUCCESS:
    case RegistrationTypes.ADD_REGISTRATION_SUCCESS:
      return {
        ...state,
        [action.data.id]: action.data,
      };
    case RegistrationTypes.UPDATE_ORG_REGISTER_SUCCESS:
      return {
        ...state,
        [action.registration.id]: {
          ...action.registration,
          org: action.data,
        },
      };
    case RegistrationTypes.UPDATE_BUILDING_REGISTER_SUCCESS:
      return {
        ...state,
        [action.registration.id]: {
          ...action.registration,
          building_json: action.data,
        },
      };
    default:
      return state;
  }
}

function ui(state = [], action) {
  switch (action.type) {
    case RegistrationTypes.GET_REGISTRATIONS_SUCCESS:
      return action.data.results;
    case RegistrationTypes.ADD_REGISTRATION_SUCCESS:
      return insertItem(state, action.data);
    case RegistrationTypes.APPROVE_REGISTRATION_SUCCESS:
    case RegistrationTypes.DECLINE_REGISTRATION_SUCCESS:
    case RegistrationTypes.UPDATE_REGISTRATION_SUCCESS:
      return updateObjectInArray(state, action.data);

    case RegistrationTypes.UPDATE_ORG_REGISTER_SUCCESS: {
      const obj = {
        ...action.registration,
        org: action.data,
      };
      return updateObjectInArray(state, obj);
    }

    case RegistrationTypes.UPDATE_BUILDING_REGISTER_SUCCESS: {
      const obj = {
        ...action.registration,
        building_json: action.data,
      };
      return updateObjectInArray(state, obj);
    }
    default:
      return state;
  }
}

function meta(state = {}, action) {
  switch (action.type) {
    case RegistrationTypes.GET_REGISTRATIONS_SUCCESS:
      const { results, ...rest } = action.data;
      return { ...state, ...rest };
    case RegistrationTypes.ADD_REGISTRATION_SUCCESS:
      return {
        ...state,
        count: state.count + 1,
      };
    default:
      return state;
  }
}

export default combineReducers({
  all,
  ui,
  meta,
});
