import { combineReducers } from "redux";
import { InviteTypes } from "../../action_types";

import { handleRequest, initialRequestState } from "./helpers";

function getInvites(state = initialRequestState(), action) {
  return handleRequest(
    InviteTypes.GET_INVITES_REQUEST,
    InviteTypes.GET_INVITES_SUCCESS,
    InviteTypes.GET_INVITES_FAIL,
    state,
    action
  );
}

function getInvite(state = initialRequestState(), action) {
  return handleRequest(
    InviteTypes.GET_INVITE_REQUEST,
    InviteTypes.GET_INVITE_SUCCESS,
    InviteTypes.GET_INVITE_FAIL,
    state,
    action
  );
}

function createInvite(state = initialRequestState(), action) {
  return handleRequest(
    InviteTypes.CREATE_INVITE_REQUEST,
    InviteTypes.CREATE_INVITE_SUCCESS,
    InviteTypes.CREATE_INVITE_FAIL,
    state,
    action
  );
}

function updateInvite(state = initialRequestState(), action) {
  return handleRequest(
    InviteTypes.UPDATE_INVITE_REQUEST,
    InviteTypes.UPDATE_INVITE_SUCCESS,
    InviteTypes.UPDATE_INVITE_FAIL,
    state,
    action
  );
}

function deleteInvite(state = initialRequestState(), action) {
  return handleRequest(
    InviteTypes.DELETE_INVITE_REQUEST,
    InviteTypes.DELETE_INVITE_SUCCESS,
    InviteTypes.DELETE_INVITE_FAIL,
    state,
    action
  );
}

function confirmInvite(state = initialRequestState(), action) {
  return handleRequest(
    InviteTypes.CONFIRM_INVITE_REQUEST,
    InviteTypes.CONFIRM_INVITE_SUCCESS,
    InviteTypes.CONFIRM_INVITE_FAIL,
    state,
    action
  );
}

function acceptInvite(state = initialRequestState(), action) {
  return handleRequest(
    InviteTypes.ACCEPT_INVITE_REQUEST,
    InviteTypes.ACCEPT_INVITE_SUCCESS,
    InviteTypes.ACCEPT_INVITE_FAIL,
    state,
    action
  );
}

function declineInvite(state = initialRequestState(), action) {
  return handleRequest(
    InviteTypes.DECLINE_INVITE_REQUEST,
    InviteTypes.DECLINE_INVITE_SUCCESS,
    InviteTypes.DECLINE_INVITE_FAIL,
    state,
    action
  );
}

function acceptInvites(state = initialRequestState(), action) {
  return handleRequest(
    InviteTypes.ACCEPT_INVITES_REQUEST,
    InviteTypes.ACCEPT_INVITES_SUCCESS,
    InviteTypes.ACCEPT_INVITES_FAIL,
    state,
    action
  );
}

function declineInvites(state = initialRequestState(), action) {
  return handleRequest(
    InviteTypes.DECLINE_INVITES_REQUEST,
    InviteTypes.DECLINE_INVITES_SUCCESS,
    InviteTypes.DECLINE_INVITES_FAIL,
    state,
    action
  );
}

export default combineReducers({
  getInvites,
  getInvite,
  createInvite,
  updateInvite,
  deleteInvite,
  confirmInvite,
  acceptInvite,
  declineInvite,
  acceptInvites,
  declineInvites,
});
