import { combineReducers } from "redux";
import { AuthTypes } from "./checkio/action_types";
import App from "@app/redux/app/reducer";
import ThemeSwitcher from "@app/redux/themeSwitcher/reducer";
import LanguageSwitcher from "@app/redux/languageSwitcher/reducer";

import checkio from "./checkio/reducers";

const appReducer = combineReducers({
  App,
  ThemeSwitcher,
  LanguageSwitcher,
  checkio,
});

const rootReducer = (state, action) => {
  // when a logout action is dispatched it will reset redux state
  if (action.type === AuthTypes.LOGOUT_SUCCESS) {
    state = undefined;
  }
  return appReducer(state, action);
};

export default rootReducer;
