export default {
  GET_ID_IMAGE_REQUEST: 'GET_ID_IMAGE_REQUEST',
  GET_ID_IMAGE_SUCCESS: 'GET_ID_IMAGE_SUCCESS',
  GET_ID_IMAGE_FAIL: 'GET_ID_IMAGE_FAIL',

  GET_SELFIE_IMAGE_REQUEST: 'GET_SELFIE_IMAGE_REQUEST',
  GET_SELFIE_IMAGE_SUCCESS: 'GET_SELFIE_IMAGE_SUCCESS',
  GET_SELFIE_IMAGE_FAIL: 'GET_SELFIE_IMAGE_FAIL',

  GET_BLOCK_IMAGE_REQUEST: 'GET_BLOCK_IMAGE_REQUEST',
  GET_BLOCK_IMAGE_SUCCESS: 'GET_BLOCK_IMAGE_SUCCESS',
  GET_BLOCK_IMAGE_FAIL: 'GET_BLOCK_IMAGE_FAIL',
};
