import { combineReducers } from "redux";
import { DeviceTypes } from "../../action_types";
import {
  arrayToObject,
  removeItem,
  insertItem,
  updateObjectInArray,
} from "@app/utils/helpers";

function all(state = {}, action) {
  switch (action.type) {
    case DeviceTypes.GET_DEVICES_SUCCESS:
      return { ...state, ...arrayToObject(action.data) };
    case DeviceTypes.GET_COMPANY_SUCCESS:
    case DeviceTypes.CREATE_COMPANY_SUCCESS:
    case DeviceTypes.UPDATE_COMPANY_SUCCESS:
    case DeviceTypes.ACCEPT_DEVICE_CONNECTION_SUCCESS:
      return {
        ...state,
        [action.data.id]: action.data,
      };
    case DeviceTypes.DELETE_COMPANY_SUCCESS:
    case DeviceTypes.REMOVE_DEVICE_CONNECTION_SUCCESS:
      const { [action.id]: _, ...newState } = state;
      return newState;
    default:
      return state;
  }
}

function ui(state = [], action) {
  switch (action.type) {
    case DeviceTypes.GET_DEVICES_SUCCESS:
      return action.data;
    case DeviceTypes.CREATE_COMPANY_SUCCESS:
      return insertItem(state, action.data);
    case DeviceTypes.UPDATE_COMPANY_SUCCESS:
    case DeviceTypes.ACCEPT_DEVICE_CONNECTION_SUCCESS:
      return updateObjectInArray(state, action.data);
    case DeviceTypes.DELETE_COMPANY_SUCCESS:
    case DeviceTypes.REMOVE_DEVICE_CONNECTION_SUCCESS:
      return removeItem(state, action.id);
    default:
      return state;
  }
}

export default combineReducers({
  all,
  ui,
});
