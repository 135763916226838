import { combineReducers } from "redux";
import { VisitorTypes, CheckinTypes } from "../../action_types";
import {
  arrayToObject,
  removeItem,
  insertItem,
  updateObjectInArray,
} from "@app/utils/helpers";

function all(state = {}, action) {
  switch (action.type) {
    case VisitorTypes.GET_VISITORS_SUCCESS:
      return { ...state, ...arrayToObject(action.data.results) };
    case VisitorTypes.GET_VISITOR_SUCCESS:
    case VisitorTypes.CREATE_VISITOR_SUCCESS:
    case VisitorTypes.UPDATE_VISITOR_SUCCESS:
      return {
        ...state,
        [action.data.id]: action.data,
      };
    case CheckinTypes.UPDATE_CHECKIN_AND_VISITOR_SUCCESS:
      return {
        ...state,
        [action.data[1].id]: action.data[1],
      };
    case VisitorTypes.DELETE_VISITOR_SUCCESS:
      const { [action.id]: _, ...newState } = state;
      return newState;
    default:
      return state;
  }
}

function ui(state = [], action) {
  switch (action.type) {
    case VisitorTypes.GET_VISITORS_SUCCESS:
      return action.data.results;
    case VisitorTypes.CREATE_VISITOR_SUCCESS:
      return insertItem(state, action.data);
    case VisitorTypes.UPDATE_VISITOR_SUCCESS:
      return updateObjectInArray(state, action.data);
    case CheckinTypes.UPDATE_CHECKIN_AND_VISITOR_SUCCESS:
      return updateObjectInArray(state, action.data[1]);
    case VisitorTypes.DELETE_VISITOR_SUCCESS:
      return removeItem(state, action.id);
    default:
      return state;
  }
}

export default combineReducers({
  all,
  ui,
});
