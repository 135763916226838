import { combineReducers } from "redux";
import { StatisticalTypes } from "../../action_types";

import { handleRequest, initialRequestState } from "./helpers";

function getStatisticalThisMonth(state = initialRequestState(), action) {
  return handleRequest(
    StatisticalTypes.GET_STATISTICAL_THIS_MONTH_REQUEST,
    StatisticalTypes.GET_STATISTICAL_THIS_MONTH_SUCCESS,
    StatisticalTypes.GET_STATISTICAL_THIS_MONTH_FAIL,
    state,
    action
  );
}

function getStatisticalCheckinByCompanies(state = initialRequestState(), action) {
  return handleRequest(
    StatisticalTypes.GET_STATISTICAL_CHECKIN_BY_COMPANIES_REQUEST,
    StatisticalTypes.GET_STATISTICAL_CHECKIN_BY_COMPANIES_SUCCESS,
    StatisticalTypes.GET_STATISTICAL_CHECKIN_BY_COMPANIES_FAIL,
    state,
    action
  );
}

function getStatisticalCheckinByType(state = initialRequestState(), action) {
  return handleRequest(
    StatisticalTypes.GET_STATISTICAL_CHECKIN_BY_TYPE_REQUEST,
    StatisticalTypes.GET_STATISTICAL_CHECKIN_BY_TYPE_SUCCESS,
    StatisticalTypes.GET_STATISTICAL_CHECKIN_BY_TYPE_FAIL,
    state,
    action
  );
}

function getStatisticalCheckinByStructure(state = initialRequestState(), action) {
  return handleRequest(
    StatisticalTypes.GET_STATISTICAL_CHECKIN_BY_STRUCTURE_REQUEST,
    StatisticalTypes.GET_STATISTICAL_CHECKIN_BY_STRUCTURE_SUCCESS,
    StatisticalTypes.GET_STATISTICAL_CHECKIN_BY_STRUCTURE_FAIL,
    state,
    action
  );
}

function getStatisticalCheckinByDayInWeek(state = initialRequestState(), action) {
  return handleRequest(
    StatisticalTypes.GET_STATISTICAL_CHECKIN_BY_DAY_IN_WEEK_REQUEST,
    StatisticalTypes.GET_STATISTICAL_CHECKIN_BY_DAY_IN_WEEK_SUCCESS,
    StatisticalTypes.GET_STATISTICAL_CHECKIN_BY_DAY_IN_WEEK_FAIL,
    state,
    action
  );
}

function getStatisticalCheckinEachDay(state = initialRequestState(), action) {
  return handleRequest(
    StatisticalTypes.GET_STATISTICAL_CHECKIN_EACH_DAY_REQUEST,
    StatisticalTypes.GET_STATISTICAL_CHECKIN_EACH_DAY_SUCCESS,
    StatisticalTypes.GET_STATISTICAL_CHECKIN_EACH_DAY_FAIL,
    state,
    action
  );
}

export default combineReducers({
  getStatisticalThisMonth,
  getStatisticalCheckinByCompanies,
  getStatisticalCheckinByType,
  getStatisticalCheckinByStructure,
  getStatisticalCheckinByDayInWeek,
  getStatisticalCheckinEachDay,
});
