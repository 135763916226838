import React, { lazy, Suspense } from 'react';
import { Route, Redirect, BrowserRouter as Router, Switch, useLocation } from 'react-router-dom';

import Loader from '@app/components/utility/loader';
import ErrorBoundary from './ErrorBoundary';
import { PUBLIC_ROUTE } from './route.constants';
import Auth from '@app/components/Auth';

const Dashboard = lazy(() => import('./containers/Dashboard/Dashboard'));
const Notfound = lazy(() => import('./components/404'));

const publicRoutes = [
  {
    path: PUBLIC_ROUTE.LANDING,
    exact: true,
    component: lazy(() => import('@app/containers/Pages/Login')),
  },
  {
    path: PUBLIC_ROUTE.SIGN_IN,
    exact: true,
    component: lazy(() => import('@app/containers/Pages/Login')),
  },
  {
    path: PUBLIC_ROUTE.SIGN_UP,
    exact: true,
    component: lazy(() => import('@app/containers/Pages/Signup')),
  },
  {
    path: PUBLIC_ROUTE.SIGN_UP_SUCCESS,
    exact: true,
    component: lazy(() => import('@app/containers/Pages/Signup/SignupSuccess')),
  },
  {
    path: PUBLIC_ROUTE.FORGET_PASSWORD,
    component: lazy(() => import('./containers/Pages/ForgetPassword')),
  },
  {
    path: PUBLIC_ROUTE.VERIFY_EMAIL,
    component: lazy(() => import('./containers/Pages/VerifyEmail')),
  },
  {
    path: PUBLIC_ROUTE.RESET_PASSWORD,
    component: lazy(() => import('./containers/Pages/ResetPassword')),
  },
  {
    path: PUBLIC_ROUTE.CHANGE_PASSWORD,
    component: lazy(() => import('./containers/Pages/ChangePassword')),
  },
  {
    path: PUBLIC_ROUTE.WELCOME,
    component: lazy(() => import('./containers/Pages/Welcome')),
  },
  {
    path: PUBLIC_ROUTE.FIRST_LOGIN,
    component: lazy(() => import('./containers/Pages/FirstLogin')),
  },
  {
    path: PUBLIC_ROUTE.ERROR_PAGE,
    component: lazy(() => import('./containers/Pages/ErrorPage')),
  },
  {
    path: PUBLIC_ROUTE.SIGNUP_COMPLETE,
    component: lazy(() => import('./containers/Pages/NewAccount')),
  },
];

function PrivateRoute({ children, ...rest }) {
  let location = useLocation();
  const isLoggedIn = Auth.isLoggedIn();
  if (isLoggedIn) return <Route {...rest}>{children}</Route>;
  return (
    <Redirect
      to={{
        pathname: '/sign-in',
        state: { from: location },
      }}
    />
  );
}
export default function Routes() {
  return (
    <ErrorBoundary>
      <Suspense fallback={<Loader />}>
        <Router>
          <Switch>
            {publicRoutes.map((route, index) => (
              <Route key={index} path={route.path} exact={route.exact}>
                <route.component />
              </Route>
            ))}
            <PrivateRoute path='/'>
              <Dashboard />
            </PrivateRoute>
            {/* <Route path='/'>
              <Dashboard />
            </Route> */}
            <Route path='*'>
              <Notfound />
            </Route>
          </Switch>
        </Router>
      </Suspense>
    </ErrorBoundary>
  );
}
