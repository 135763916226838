import { combineReducers } from "redux";
import { InviteTypes } from "../../action_types";
import { arrayToObject, removeItem, insertItem, updateObjectInArray } from "@app/utils/helpers";

const format = data => {
  const [date, time] = data.schedule_checkin_time.split(" ");
  return { ...data, schedule_checkin_time: `${date}T${time}Z` }; // utc time
};

function all(state = {}, action) {
  switch (action.type) {
    case InviteTypes.GET_INVITES_SUCCESS:
      return { ...state, ...arrayToObject(action.data.results) };
    case InviteTypes.CREATE_INVITE_SUCCESS:
    case InviteTypes.UPDATE_INVITE_SUCCESS:
      const data = format(action.data);
      return {
        ...state,
        [data.id]: data,
      };
    case InviteTypes.GET_INVITE_SUCCESS:
    case InviteTypes.CONFIRM_INVITE_SUCCESS:
    case InviteTypes.ACCEPT_INVITE_SUCCESS:
    case InviteTypes.DECLINE_INVITE_SUCCESS:
      return {
        ...state,
        [action.data.id]: action.data,
      };
    case InviteTypes.DELETE_INVITE_SUCCESS:
      const { [action.id]: _, ...newState } = state;
      return newState;
    default:
      return state;
  }
}

function ui(state = [], action) {
  switch (action.type) {
    case InviteTypes.GET_INVITES_SUCCESS:
      return action.data.results;
    case InviteTypes.CREATE_INVITE_SUCCESS: {
      const data = format(action.data);
      return insertItem(state, data);
    }
    case InviteTypes.UPDATE_INVITE_SUCCESS: {
      const data = format(action.data);
      return updateObjectInArray(state, data);
    }
    case InviteTypes.CONFIRM_INVITE_SUCCESS:
    case InviteTypes.ACCEPT_INVITE_SUCCESS:
    case InviteTypes.DECLINE_INVITE_SUCCESS:
      return updateObjectInArray(state, action.data);
    case InviteTypes.DELETE_INVITE_SUCCESS:
      return removeItem(state, action.id);
    default:
      return state;
  }
}

function meta(state = {}, action) {
  switch (action.type) {
    case InviteTypes.GET_INVITES_SUCCESS:
      const { results, ...rest } = action.data;
      return { ...state, ...rest };
    case InviteTypes.DELETE_INVITE_SUCCESS: {
      const { total } = state;
      return { ...state, total: total - 1 };
    }
    default:
      return state;
  }
}

function searchedInvites(state = [], action) {
  switch (action.type) {
    case InviteTypes.GET_INVITES_SUCCESS:
      return action.data.results;
    case InviteTypes.CREATE_INVITE_SUCCESS: {
      const data = format(action.data);
      return insertItem(state, data);
    }
    case InviteTypes.UPDATE_INVITE_SUCCESS: {
      const data = format(action.data);
      return updateObjectInArray(state, data);
    }
    case InviteTypes.CONFIRM_INVITE_SUCCESS:
    case InviteTypes.ACCEPT_INVITE_SUCCESS:
    case InviteTypes.DECLINE_INVITE_SUCCESS:
      return updateObjectInArray(state, action.data);
    case InviteTypes.DELETE_INVITE_SUCCESS:
      return removeItem(state, action.id);
    default:
      return state;
  }
}

export default combineReducers({
  all,
  ui,
  meta,
  searchedInvites,
});
