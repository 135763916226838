export default {
  GET_STATISTICAL_THIS_MONTH_REQUEST: "GET_STATISTICAL_THIS_MONTH_REQUEST",
  GET_STATISTICAL_THIS_MONTH_SUCCESS: "GET_STATISTICAL_THIS_MONTH_SUCCESS",
  GET_STATISTICAL_THIS_MONTH_FAIL: "GET_STATISTICAL_THIS_MONTH_FAIL",

  GET_STATISTICAL_CHECKIN_BY_COMPANIES_REQUEST: "GET_STATISTICAL_CHECKIN_BY_COMPANIES_REQUEST",
  GET_STATISTICAL_CHECKIN_BY_COMPANIES_SUCCESS: "GET_STATISTICAL_CHECKIN_BY_COMPANIES_SUCCESS",
  GET_STATISTICAL_CHECKIN_BY_COMPANIES_FAIL: "GET_STATISTICAL_CHECKIN_BY_COMPANIES_FAIL",

  GET_STATISTICAL_CHECKIN_BY_TYPE_REQUEST: "GET_STATISTICAL_CHECKIN_BY_TYPE_REQUEST",
  GET_STATISTICAL_CHECKIN_BY_TYPE_SUCCESS: "GET_STATISTICAL_CHECKIN_BY_TYPE_SUCCESS",
  GET_STATISTICAL_CHECKIN_BY_TYPE_FAIL: "GET_STATISTICAL_CHECKIN_BY_TYPE_FAIL",

  GET_STATISTICAL_CHECKIN_BY_STRUCTURE_REQUEST: "GET_STATISTICAL_CHECKIN_BY_STRUCTURE_REQUEST",
  GET_STATISTICAL_CHECKIN_BY_STRUCTURE_SUCCESS: "GET_STATISTICAL_CHECKIN_BY_STRUCTURE_SUCCESS",
  GET_STATISTICAL_CHECKIN_BY_STRUCTURE_FAIL: "GET_STATISTICAL_CHECKIN_BY_STRUCTURE_FAIL",

  GET_STATISTICAL_CHECKIN_BY_DAY_IN_WEEK_REQUEST: "GET_STATISTICAL_CHECKIN_BY_DAY_IN_WEEK_REQUEST",
  GET_STATISTICAL_CHECKIN_BY_DAY_IN_WEEK_SUCCESS: "GET_STATISTICAL_CHECKIN_BY_DAY_IN_WEEK_SUCCESS",
  GET_STATISTICAL_CHECKIN_BY_DAY_IN_WEEK_FAIL: "GET_STATISTICAL_CHECKIN_BY_DAY_IN_WEEK_FAIL",

  GET_STATISTICAL_CHECKIN_EACH_DAY_REQUEST: "GET_STATISTICAL_CHECKIN_EACH_DAY_REQUEST",
  GET_STATISTICAL_CHECKIN_EACH_DAY_SUCCESS: "GET_STATISTICAL_CHECKIN_EACH_DAY_SUCCESS",
  GET_STATISTICAL_CHECKIN_EACH_DAY_FAIL: "GET_STATISTICAL_CHECKIN_EACH_DAY_FAIL",
};
