import { combineReducers } from "redux";
import { CompanyTypes } from "../../action_types";

import { handleRequest, initialRequestState } from "./helpers";

function getCompanies(state = initialRequestState(), action) {
  return handleRequest(
    CompanyTypes.GET_COMPANIES_REQUEST,
    CompanyTypes.GET_COMPANIES_SUCCESS,
    CompanyTypes.GET_COMPANIES_FAIL,
    state,
    action
  );
}

function getCompany(state = initialRequestState(), action) {
  return handleRequest(
    CompanyTypes.GET_COMPANY_REQUEST,
    CompanyTypes.GET_COMPANY_SUCCESS,
    CompanyTypes.GET_COMPANY_FAIL,
    state,
    action
  );
}

function createCompany(state = initialRequestState(), action) {
  return handleRequest(
    CompanyTypes.CREATE_COMPANY_REQUEST,
    CompanyTypes.CREATE_COMPANY_SUCCESS,
    CompanyTypes.CREATE_COMPANY_FAIL,
    state,
    action
  );
}

function updateCompany(state = initialRequestState(), action) {
  return handleRequest(
    CompanyTypes.UPDATE_COMPANY_REQUEST,
    CompanyTypes.UPDATE_COMPANY_SUCCESS,
    CompanyTypes.UPDATE_COMPANY_FAIL,
    state,
    action
  );
}

function deleteCompany(state = initialRequestState(), action) {
  return handleRequest(
    CompanyTypes.DELETE_COMPANY_REQUEST,
    CompanyTypes.DELETE_COMPANY_SUCCESS,
    CompanyTypes.DELETE_COMPANY_FAIL,
    state,
    action
  );
}

function importCompaniesExcel(state = initialRequestState(), action) {
  return handleRequest(
    CompanyTypes.IMPORT_COMPANIES_EXCEL_REQUEST,
    CompanyTypes.IMPORT_COMPANIES_EXCEL_SUCCESS,
    CompanyTypes.IMPORT_COMPANIES_EXCEL_FAIL,
    state,
    action
  );
}

function exportCompaniesExcel(state = initialRequestState(), action) {
  return handleRequest(
    CompanyTypes.EXPORT_COMPANIES_EXCEL_REQUEST,
    CompanyTypes.EXPORT_COMPANIES_EXCEL_SUCCESS,
    CompanyTypes.EXPORT_COMPANIES_EXCEL_FAIL,
    state,
    action
  );
}

function registerContact(state = initialRequestState(), action) {
  return handleRequest(
    CompanyTypes.REGISTER_CONTACT_REQUEST,
    CompanyTypes.REGISTER_CONTACT_SUCCESS,
    CompanyTypes.REGISTER_CONTACT_FAIL,
    state,
    action
  );
}

export default combineReducers({
  getCompanies,
  getCompany,
  createCompany,
  updateCompany,
  deleteCompany,
  registerContact,
  importCompaniesExcel,
  exportCompaniesExcel,
});
