import { combineReducers } from "redux";
import { BillingTypes } from "../../action_types";

function billing(state = {}, action) {
  switch (action.type) {
    case BillingTypes.GET_BILLING_SUCCESS:
      return action.data;
    default:
      return state;
  }
}

export default combineReducers({
  billing,
});
