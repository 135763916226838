import { combineReducers } from "redux";
import { CommonTypes } from "../../action_types";

function page_title(state = "", action) {
  switch (action.type) {
    case CommonTypes.SET_PAGE_TITLE:
      return action.data;
    default:
      return state;
  }
}

export default combineReducers({
  page_title,
});
