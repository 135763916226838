import { combineReducers } from 'redux';
import { RegistrationTypes } from '../../action_types';

import { handleRequest, initialRequestState } from './helpers';

function getRegistrations(state = initialRequestState(), action) {
  return handleRequest(
    RegistrationTypes.GET_REGISTRATIONS_REQUEST,
    RegistrationTypes.GET_REGISTRATIONS_SUCCESS,
    RegistrationTypes.GET_REGISTRATIONS_FAIL,
    state,
    action
  );
}

function getRegistration(state = initialRequestState(), action) {
  return handleRequest(
    RegistrationTypes.GET_REGISTRATION_REQUEST,
    RegistrationTypes.GET_REGISTRATION_SUCCESS,
    RegistrationTypes.GET_REGISTRATION_FAIL,
    state,
    action
  );
}

function approveRegistration(state = initialRequestState(), action) {
  return handleRequest(
    RegistrationTypes.APPROVE_REGISTRATION_REQUEST,
    RegistrationTypes.APPROVE_REGISTRATION_SUCCESS,
    RegistrationTypes.APPROVE_REGISTRATION_FAIL,
    state,
    action
  );
}

function declineRegistration(state = initialRequestState(), action) {
  return handleRequest(
    RegistrationTypes.DECLINE_REGISTRATION_REQUEST,
    RegistrationTypes.DECLINE_REGISTRATION_SUCCESS,
    RegistrationTypes.DECLINE_REGISTRATION_FAIL,
    state,
    action
  );
}

function createRegistration(state = initialRequestState(), action) {
  return handleRequest(
    RegistrationTypes.ADD_REGISTRATION_REQUEST,
    RegistrationTypes.ADD_REGISTRATION_SUCCESS,
    RegistrationTypes.ADD_REGISTRATION_FAIL,
    state,
    action
  );
}

function updateRegistration(state = initialRequestState(), action) {
  return handleRequest(
    RegistrationTypes.UPDATE_REGISTRATION_REQUEST,
    RegistrationTypes.UPDATE_REGISTRATION_SUCCESS,
    RegistrationTypes.UPDATE_REGISTRATION_FAIL,
    state,
    action
  );
}

function updateOrgRegister(state = initialRequestState(), action) {
  return handleRequest(
    RegistrationTypes.UPDATE_ORG_REGISTER_REQUEST,
    RegistrationTypes.UPDATE_ORG_REGISTER_SUCCESS,
    RegistrationTypes.UPDATE_ORG_REGISTER_FAIL,
    state,
    action
  );
}

function updateBuildingRegister(state = initialRequestState(), action) {
  return handleRequest(
    RegistrationTypes.UPDATE_BUILDING_REGISTER_REQUEST,
    RegistrationTypes.UPDATE_BUILDING_REGISTER_SUCCESS,
    RegistrationTypes.UPDATE_BUILDING_REGISTER_FAIL,
    state,
    action
  );
}

export default combineReducers({
  getRegistrations,
  getRegistration,
  approveRegistration,
  declineRegistration,
  createRegistration,
  updateBuildingRegister,
  updateOrgRegister,
  updateRegistration,
});
