import { combineReducers } from 'redux';
import { AuthTypes } from '../../action_types';

function user(state = {}, action) {
  switch (action.type) {
    case AuthTypes.LOGIN_SUCCESS:
    case AuthTypes.GET_ME_SUCCESS:
      return { ...state, ...action.data };
    default:
      return state;
  }
}

function loginInfo(state = {}, action) {
  switch (action.type) {
    case AuthTypes.CHECK_LOGIN_SUCCESS:
      return { ...state, email: action.email, password: action.password };
    case AuthTypes.LOGIN_SUCCESS:
      return {};
    default:
      return state;
  }
}

function existed_user(state = false, action) {
  switch (action.type) {
    case AuthTypes.CHECK_EMAIL_SUCCESS:
      return action.data?.existed_user;
    default:
      return state;
  }
}

export default combineReducers({
  user,
  loginInfo,
  existed_user,
});
