export default {
  GET_INVITES_REQUEST: "GET_INVITES_REQUEST",
  GET_INVITES_SUCCESS: "GET_INVITES_SUCCESS",
  GET_INVITES_FAIL: "GET_INVITES_FAIL",

  GET_INVITE_REQUEST: "GET_INVITE_REQUEST",
  GET_INVITE_SUCCESS: "GET_INVITE_SUCCESS",
  GET_INVITE_FAIL: "GET_INVITE_FAIL",

  CREATE_INVITE_REQUEST: "CREATE_INVITE_REQUEST",
  CREATE_INVITE_SUCCESS: "CREATE_INVITE_SUCCESS",
  CREATE_INVITE_FAIL: "CREATE_INVITE_FAIL",

  UPDATE_INVITE_REQUEST: "UPDATE_INVITE_REQUEST",
  UPDATE_INVITE_SUCCESS: "UPDATE_INVITE_SUCCESS",
  UPDATE_INVITE_FAIL: "UPDATE_INVITE_FAIL",

  DELETE_INVITE_REQUEST: "DELETE_INVITE_REQUEST",
  DELETE_INVITE_SUCCESS: "DELETE_INVITE_SUCCESS",
  DELETE_INVITE_FAIL: "DELETE_INVITE_FAIL",

  CONFIRM_INVITE_REQUEST: "CONFIRM_INVITE_REQUEST",
  CONFIRM_INVITE_SUCCESS: "CONFIRM_INVITE_SUCCESS",
  CONFIRM_INVITE_FAIL: "CONFIRM_INVITE_FAIL",

  ACCEPT_INVITE_REQUEST: "ACCEPT_INVITE_REQUEST",
  ACCEPT_INVITE_SUCCESS: "ACCEPT_INVITE_SUCCESS",
  ACCEPT_INVITE_FAIL: "ACCEPT_INVITE_FAIL",

  DECLINE_INVITE_REQUEST: "DECLINE_INVITE_REQUEST",
  DECLINE_INVITE_SUCCESS: "DECLINE_INVITE_SUCCESS",
  DECLINE_INVITE_FAIL: "DECLINE_INVITE_FAIL",

  ACCEPT_INVITES_REQUEST: "ACCEPT_INVITES_REQUEST",
  ACCEPT_INVITES_SUCCESS: "ACCEPT_INVITES_SUCCESS",
  ACCEPT_INVITES_FAIL: "ACCEPT_INVITES_FAIL",

  DECLINE_INVITES_REQUEST: "DECLINE_INVITES_REQUEST",
  DECLINE_INVITES_SUCCESS: "DECLINE_INVITES_SUCCESS",
  DECLINE_INVITES_FAIL: "DECLINE_INVITES_FAIL",
};
