import { combineReducers } from 'redux';
import { BuildingTypes } from '../../action_types';

import { handleRequest, initialRequestState } from './helpers';

function getBuildings(state = initialRequestState(), action) {
  return handleRequest(
    BuildingTypes.GET_BUILDINGS_REQUEST,
    BuildingTypes.GET_BUILDINGS_SUCCESS,
    BuildingTypes.GET_BUILDINGS_FAIL,
    state,
    action
  );
}

function getBuilding(state = initialRequestState(), action) {
  return handleRequest(
    BuildingTypes.GET_BUILDING_REQUEST,
    BuildingTypes.GET_BUILDING_SUCCESS,
    BuildingTypes.GET_BUILDING_FAIL,
    state,
    action
  );
}

function createBuilding(state = initialRequestState(), action) {
  return handleRequest(
    BuildingTypes.CREATE_BUILDING_REQUEST,
    BuildingTypes.CREATE_BUILDING_SUCCESS,
    BuildingTypes.CREATE_BUILDING_FAIL,
    state,
    action
  );
}

function updateBuilding(state = initialRequestState(), action) {
  return handleRequest(
    BuildingTypes.UPDATE_BUILDING_REQUEST,
    BuildingTypes.UPDATE_BUILDING_SUCCESS,
    BuildingTypes.UPDATE_BUILDING_FAIL,
    state,
    action
  );
}

function activateBuilding(state = initialRequestState(), action) {
  return handleRequest(
    BuildingTypes.ACTIVATE_BUILDING_REQUEST,
    BuildingTypes.ACTIVATE_BUILDING_SUCCESS,
    BuildingTypes.ACTIVATE_BUILDING_FAIL,
    state,
    action
  );
}

function deactivateBuilding(state = initialRequestState(), action) {
  return handleRequest(
    BuildingTypes.DEACTIVATE_BUILDING_REQUEST,
    BuildingTypes.DEACTIVATE_BUILDING_SUCCESS,
    BuildingTypes.DEACTIVATE_BUILDING_FAIL,
    state,
    action
  );
}

function getLogo(state = initialRequestState(), action) {
  return handleRequest(
    BuildingTypes.GET_LOGO_REQUEST,
    BuildingTypes.GET_LOGO_SUCCESS,
    BuildingTypes.GET_LOGO_FAIL,
    state,
    action
  );
}

function uploadLogo(state = initialRequestState(), action) {
  return handleRequest(
    BuildingTypes.UPLOAD_LOGO_REQUEST,
    BuildingTypes.UPLOAD_LOGO_SUCCESS,
    BuildingTypes.UPLOAD_LOGO_FAIL,
    state,
    action
  );
}

function getBuildingsByOrgId(state = initialRequestState(), action) {
  return handleRequest(
    BuildingTypes.GET_BUILDINGS_BY_ORG_REQUEST,
    BuildingTypes.GET_BUILDINGS_BY_ORG_SUCCESS,
    BuildingTypes.GET_BUILDINGS_BY_ORG_FAIL,
    state,
    action
  );
}

export default combineReducers({
  getBuildings,
  getBuilding,
  createBuilding,
  updateBuilding,
  activateBuilding,
  deactivateBuilding,
  getLogo,
  uploadLogo,
  getBuildingsByOrgId,
});
