import { AuthTypes } from './checkio/action_types';

export function callAPIMiddleware({ dispatch, getState }) {
  return next => action => {
    const { types, callAPI, shouldCallAPI = () => true, payload = {} } = action;

    if (!types) {
      // Normal action: pass it on
      return next(action);
    }

    if (!Array.isArray(types) || types.length !== 3 || !types.every(type => typeof type === 'string')) {
      throw new Error('Expected an array of three string types.');
    }

    if (typeof callAPI !== 'function') {
      throw new Error('Expected callAPI to be a function.');
    }

    if (!shouldCallAPI(getState())) {
      return;
    }

    const [requestType, successType, failureType] = types;

    dispatch(
      Object.assign({}, payload, {
        type: requestType,
      })
    );

    return callAPI().then(
      response => {
        // console.log(response);
        return dispatch(
          Object.assign({}, payload, {
            data: response,
            type: successType,
          })
        );
      },
      error => {
        const err = error.response?.data;
        if (err && err.code === 6 && err.message === 'Not authenticated') {
          return dispatch({ type: AuthTypes.LOGOUT_SUCCESS });
        }

        if (err && (err.code === 1 || err.code === 2 || err.code === 3) && err.object_name) {
          return dispatch(
            Object.assign({}, payload, {
              error: `${err.code}_${err.object_name}`,
              type: failureType,
            })
          );
        }

        return dispatch(
          Object.assign({}, payload, {
            error: String(err.code),
            type: failureType,
          })
        );
      }
    );
  };
}
