import { combineReducers } from 'redux';
import { StatisticalTypes } from '../../action_types';
// import { arrayToObject, removeItem, insertItem, updateObjectInArray } from "@app/utils/helpers";

function thisMonth(state = {}, action) {
  switch (action.type) {
    case StatisticalTypes.GET_STATISTICAL_THIS_MONTH_SUCCESS:
      return action.data;
    default:
      return state;
  }
}

function byCompanies(state = [], action) {
  switch (action.type) {
    case StatisticalTypes.GET_STATISTICAL_CHECKIN_BY_COMPANIES_SUCCESS:
      return action.data;
    default:
      return state;
  }
}

function byType(state = {}, action) {
  switch (action.type) {
    case StatisticalTypes.GET_STATISTICAL_CHECKIN_BY_TYPE_SUCCESS:
      return action.data;
    default:
      return state;
  }
}

function byStructure(state = {}, action) {
  switch (action.type) {
    case StatisticalTypes.GET_STATISTICAL_CHECKIN_BY_STRUCTURE_SUCCESS:
      return action.data;
    default:
      return state;
  }
}

function byDayInWeek(state = {}, action) {
  switch (action.type) {
    case StatisticalTypes.GET_STATISTICAL_CHECKIN_BY_DAY_IN_WEEK_SUCCESS:
      return action.data;
    default:
      return state;
  }
}

function eachDay(state = {}, action) {
  switch (action.type) {
    case StatisticalTypes.GET_STATISTICAL_CHECKIN_EACH_DAY_SUCCESS:
      return action.data;
    default:
      return state;
  }
}

export default combineReducers({
  thisMonth,
  byCompanies,
  byType,
  byStructure,
  byDayInWeek,
  eachDay,
});
