export default {
  GET_VISITORS_REQUEST: "GET_VISITORS_REQUEST",
  GET_VISITORS_SUCCESS: "GET_VISITORS_SUCCESS",
  GET_VISITORS_FAIL: "GET_VISITORS_FAIL",

  GET_VISITOR_REQUEST: "GET_VISITOR_REQUEST",
  GET_VISITOR_SUCCESS: "GET_VISITOR_SUCCESS",
  GET_VISITOR_FAIL: "GET_VISITOR_FAIL",

  UPDATE_VISITOR_REQUEST: "UPDATE_VISITOR_REQUEST",
  UPDATE_VISITOR_SUCCESS: "UPDATE_VISITOR_SUCCESS",
  UPDATE_VISITOR_FAIL: "UPDATE_VISITOR_FAIL",

  CREATE_VISITOR_REQUEST: "CREATE_VISITOR_REQUEST",
  CREATE_VISITOR_SUCCESS: "CREATE_VISITOR_SUCCESS",
  CREATE_VISITOR_FAIL: "CREATE_VISITOR_FAIL",

  DELETE_VISITOR_REQUEST: "DELETE_VISITOR_REQUEST",
  DELETE_VISITOR_SUCCESS: "DELETE_VISITOR_SUCCESS",
  DELETE_VISITOR_FAIL: "DELETE_VISITOR_FAIL",
};
