export default {
  GET_COMPANIES_REQUEST: "GET_COMPANIES_REQUEST",
  GET_COMPANIES_SUCCESS: "GET_COMPANIES_SUCCESS",
  GET_COMPANIES_FAIL: "GET_COMPANIES_FAIL",

  GET_COMPANY_REQUEST: "GET_COMPANY_REQUEST",
  GET_COMPANY_SUCCESS: "GET_COMPANY_SUCCESS",
  GET_COMPANY_FAIL: "GET_COMPANY_FAIL",

  CREATE_COMPANY_REQUEST: "CREATE_COMPANY_REQUEST",
  CREATE_COMPANY_SUCCESS: "CREATE_COMPANY_SUCCESS",
  CREATE_COMPANY_FAIL: "CREATE_COMPANY_FAIL",

  UPDATE_COMPANY_REQUEST: "UPDATE_COMPANY_REQUEST",
  UPDATE_COMPANY_SUCCESS: "UPDATE_COMPANY_SUCCESS",
  UPDATE_COMPANY_FAIL: "UPDATE_COMPANY_FAIL",

  DELETE_COMPANY_REQUEST: "DELETE_COMPANY_REQUEST",
  DELETE_COMPANY_SUCCESS: "DELETE_COMPANY_SUCCESS",
  DELETE_COMPANY_FAIL: "DELETE_COMPANY_FAIL",

  IMPORT_COMPANIES_EXCEL_REQUEST: "IMPORT_COMPANIES_EXCEL_REQUEST",
  IMPORT_COMPANIES_EXCEL_SUCCESS: "IMPORT_COMPANIES_EXCEL_SUCCESS",
  IMPORT_COMPANIES_EXCEL_FAIL: "IMPORT_COMPANIES_EXCEL_FAIL",

  EXPORT_COMPANIES_EXCEL_REQUEST: "EXPORT_COMPANIES_EXCEL_REQUEST",
  EXPORT_COMPANIES_EXCEL_SUCCESS: "EXPORT_COMPANIES_EXCEL_SUCCESS",
  EXPORT_COMPANIES_EXCEL_FAIL: "EXPORT_COMPANIES_EXCEL_FAIL",

  REGISTER_CONTACT_REQUEST: "REGISTER_CONTACT_REQUEST",
  REGISTER_CONTACT_SUCCESS: "REGISTER_CONTACT_SUCCESS",
  REGISTER_CONTACT_FAIL: "REGISTER_CONTACT_FAIL",
};
