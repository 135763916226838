import { combineReducers } from 'redux';
import auth from './auth';
import user from './user';
import company from './company';
import device from './device';
import invite from './invite';
import checkin from './checkin';
import visitor from './visitor';
import image from './image';
import billing from './billing';
import statistical from './statistical';
import question from './question';
import building from './building';
import block from './block';
import registration from './registration';

export default combineReducers({
  auth,
  user,
  company,
  device,
  invite,
  checkin,
  visitor,
  image,
  billing,
  statistical,
  question,
  building,
  block,
  registration,
});
