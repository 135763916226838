import { combineReducers } from 'redux';
import auth from './auth';
import user from './user';
import company from './company';
import common from './common';
import invite from './invite';
import device from './device';
import checkin from './checkin';
import visitor from './visitor';
import image from './image';
import billing from './billing';
import statistical from './statistical';
import question from './question';
import building from './building';
import block from './block';
import registration from './registration';
import building_of_org from './building_of_org';

import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

const buildingPersistConfig = {
  key: 'building',
  storage: storage,
  whitelist: ['currentBuilding'],
};

export default combineReducers({
  auth,
  user,
  company,
  common,
  invite,
  device,
  checkin,
  visitor,
  image,
  billing,
  statistical,
  question,
  block,
  building: persistReducer(buildingPersistConfig, building),
  registration,
  building_of_org,
});
