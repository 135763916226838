import { combineReducers } from "redux";
import { QuestionTypes } from "../../action_types";

import { handleRequest, initialRequestState } from "./helpers";

function getQuestions(state = initialRequestState(), action) {
  return handleRequest(
    QuestionTypes.GET_QUESTIONS_REQUEST,
    QuestionTypes.GET_QUESTIONS_SUCCESS,
    QuestionTypes.GET_QUESTIONS_FAIL,
    state,
    action
  );
}

function createQuestion(state = initialRequestState(), action) {
  return handleRequest(
    QuestionTypes.CREATE_QUESTION_REQUEST,
    QuestionTypes.CREATE_QUESTION_SUCCESS,
    QuestionTypes.CREATE_QUESTION_FAIL,
    state,
    action
  );
}

function updateQuestion(state = initialRequestState(), action) {
  return handleRequest(
    QuestionTypes.UPDATE_QUESTION_REQUEST,
    QuestionTypes.UPDATE_QUESTION_SUCCESS,
    QuestionTypes.UPDATE_QUESTION_FAIL,
    state,
    action
  );
}

function deleteQuestion(state = initialRequestState(), action) {
  return handleRequest(
    QuestionTypes.DELETE_QUESTION_REQUEST,
    QuestionTypes.DELETE_QUESTION_SUCCESS,
    QuestionTypes.DELETE_QUESTION_FAIL,
    state,
    action
  );
}

export default combineReducers({
  getQuestions,
  createQuestion,
  updateQuestion,
  deleteQuestion,
});
