export default {
  GET_CHECKINS_REQUEST: 'GET_CHECKINS_REQUEST',
  GET_CHECKINS_SUCCESS: 'GET_CHECKINS_SUCCESS',
  GET_CHECKINS_FAIL: 'GET_CHECKINS_FAIL',

  GET_CHECKIN_REQUEST: 'GET_CHECKIN_REQUEST',
  GET_CHECKIN_SUCCESS: 'GET_CHECKIN_SUCCESS',
  GET_CHECKIN_FAIL: 'GET_CHECKIN_FAIL',

  CREATE_CHECKIN_REQUEST: 'CREATE_CHECKIN_REQUEST',
  CREATE_CHECKIN_SUCCESS: 'CREATE_CHECKIN_SUCCESS',
  CREATE_CHECKIN_FAIL: 'CREATE_CHECKIN_FAIL',

  UPDATE_CHECKIN_REQUEST: 'UPDATE_CHECKIN_REQUEST',
  UPDATE_CHECKIN_SUCCESS: 'UPDATE_CHECKIN_SUCCESS',
  UPDATE_CHECKIN_FAIL: 'UPDATE_CHECKIN_FAIL',

  CHECKOUT_REQUEST: 'CHECKOUT_REQUEST',
  CHECKOUT_SUCCESS: 'CHECKOUT_SUCCESS',
  CHECKOUT_FAIL: 'CHECKOUT_FAIL',

  OCR_REQUEST: 'OCR_REQUEST',
  OCR_SUCCESS: 'OCR_SUCCESS',
  OCR_FAIL: 'OCR_FAIL',

  CLEAR_PREV_OCR_RESULT: 'CLEAR_PREV_OCR_RESULT',

  MARK_LOST_ACCESS_CARD_REQUEST: 'MARK_LOST_ACCESS_CARD_REQUEST',
  MARK_LOST_ACCESS_CARD_SUCCESS: 'MARK_LOST_ACCESS_CARD_SUCCESS',
  MARK_LOST_ACCESS_CARD_FAIL: 'MARK_LOST_ACCESS_CARD_FAIL',

  UPDATE_CHECKIN_AND_VISITOR_REQUEST: 'UPDATE_CHECKIN_AND_VISITOR_REQUEST',
  UPDATE_CHECKIN_AND_VISITOR_SUCCESS: 'UPDATE_CHECKIN_AND_VISITOR_SUCCESS',
  UPDATE_CHECKIN_AND_VISITOR_FAIL: 'UPDATE_CHECKIN_AND_VISITOR_FAIL',

  DELETE_CHECKIN_REQUEST: 'DELETE_CHECKIN_REQUEST',
  DELETE_CHECKIN_SUCCESS: 'DELETE_CHECKIN_SUCCESS',
  DELETE_CHECKIN_FAIL: 'DELETE_CHECKIN_FAIL',

  DECLINE_CHECKIN_REQUEST: 'DECLINE_CHECKIN_REQUEST',
  DECLINE_CHECKIN_SUCCESS: 'DECLINE_CHECKIN_SUCCESS',
  DECLINE_CHECKIN_FAIL: 'DECLINE_CHECKIN_FAIL',

  GET_CHECKIN_HISTORY_REQUEST: 'GET_CHECKIN_HISTORY_REQUEST',
  GET_CHECKIN_HISTORY_SUCCESS: 'GET_CHECKIN_HISTORY_SUCCESS',
  GET_CHECKIN_HISTORY_FAIL: 'GET_CHECKIN_HISTORY_FAIL',

  EXPORT_CHECKINS_EXCEL_REQUEST: 'EXPORT_CHECKINS_EXCEL_REQUEST',
  EXPORT_CHECKINS_EXCEL_SUCCESS: 'EXPORT_CHECKINS_EXCEL_SUCCESS',
  EXPORT_CHECKINS_EXCEL_FAIL: 'EXPORT_CHECKINS_EXCEL_FAIL',
};
