export default {
  GET_BLOCKS_REQUEST: 'GET_BLOCKS_REQUEST',
  GET_BLOCKS_SUCCESS: 'GET_BLOCKS_SUCCESS',
  GET_BLOCKS_FAIL: 'GET_BLOCKS_FAIL',

  GET_BLOCK_REQUEST: 'GET_BLOCK_REQUEST',
  GET_BLOCK_SUCCESS: 'GET_BLOCK_SUCCESS',
  GET_BLOCK_FAIL: 'GET_BLOCK_FAIL',

  CREATE_BLOCK_REQUEST: 'CREATE_BLOCK_REQUEST',
  CREATE_BLOCK_SUCCESS: 'CREATE_BLOCK_SUCCESS',
  CREATE_BLOCK_FAIL: 'CREATE_BLOCK_FAIL',

  UPDATE_BLOCK_REQUEST: 'UPDATE_BLOCK_REQUEST',
  UPDATE_BLOCK_SUCCESS: 'UPDATE_BLOCK_SUCCESS',
  UPDATE_BLOCK_FAIL: 'UPDATE_BLOCK_FAIL',

  DELETE_BLOCK_REQUEST: 'DELETE_BLOCK_REQUEST',
  DELETE_BLOCK_SUCCESS: 'DELETE_BLOCK_SUCCESS',
  DELETE_BLOCK_FAIL: 'DELETE_BLOCK_FAIL',

  IMPORT_BLOCKS_EXCEL_REQUEST: 'IMPORT_BLOCKS_EXCEL_REQUEST',
  IMPORT_BLOCKS_EXCEL_SUCCESS: 'IMPORT_BLOCKS_EXCEL_SUCCESS',
  IMPORT_BLOCKS_EXCEL_FAIL: 'IMPORT_BLOCKS_EXCEL_FAIL',

  EXPORT_BLOCKS_EXCEL_REQUEST: 'EXPORT_BLOCKS_EXCEL_REQUEST',
  EXPORT_BLOCKS_EXCEL_SUCCESS: 'EXPORT_BLOCKS_EXCEL_SUCCESS',
  EXPORT_BLOCKS_EXCEL_FAIL: 'EXPORT_BLOCKS_EXCEL_FAIL',
};
