import { combineReducers } from 'redux';
import { BlockTypes } from '../../action_types';

import { handleRequest, initialRequestState } from './helpers';

function getBlocks(state = initialRequestState(), action) {
  return handleRequest(
    BlockTypes.GET_BLOCKS_REQUEST,
    BlockTypes.GET_BLOCKS_SUCCESS,
    BlockTypes.GET_BLOCKS_FAIL,
    state,
    action
  );
}

function getBlock(state = initialRequestState(), action) {
  return handleRequest(
    BlockTypes.GET_BLOCK_REQUEST,
    BlockTypes.GET_BLOCK_SUCCESS,
    BlockTypes.GET_BLOCK_FAIL,
    state,
    action
  );
}

function createBlock(state = initialRequestState(), action) {
  return handleRequest(
    BlockTypes.CREATE_BLOCK_REQUEST,
    BlockTypes.CREATE_BLOCK_SUCCESS,
    BlockTypes.CREATE_BLOCK_FAIL,
    state,
    action
  );
}

function updateBlock(state = initialRequestState(), action) {
  return handleRequest(
    BlockTypes.UPDATE_BLOCK_REQUEST,
    BlockTypes.UPDATE_BLOCK_SUCCESS,
    BlockTypes.UPDATE_BLOCK_FAIL,
    state,
    action
  );
}

function deleteBlock(state = initialRequestState(), action) {
  return handleRequest(
    BlockTypes.DELETE_BLOCK_REQUEST,
    BlockTypes.DELETE_BLOCK_SUCCESS,
    BlockTypes.DELETE_BLOCK_FAIL,
    state,
    action
  );
}

function importBlocksExcel(state = initialRequestState(), action) {
  return handleRequest(
    BlockTypes.IMPORT_BLOCKS_EXCEL_REQUEST,
    BlockTypes.IMPORT_BLOCKS_EXCEL_SUCCESS,
    BlockTypes.IMPORT_BLOCKS_EXCEL_FAIL,
    state,
    action
  );
}

function exportBlocksExcel(state = initialRequestState(), action) {
  return handleRequest(
    BlockTypes.EXPORT_BLOCKS_EXCEL_REQUEST,
    BlockTypes.EXPORT_BLOCKS_EXCEL_SUCCESS,
    BlockTypes.EXPORT_BLOCKS_EXCEL_FAIL,
    state,
    action
  );
}

export default combineReducers({
  getBlocks,
  getBlock,
  createBlock,
  updateBlock,
  deleteBlock,
  importBlocksExcel,
  exportBlocksExcel,
});
