import { combineReducers } from "redux";
import { BillingTypes } from "../../action_types";

import { handleRequest, initialRequestState } from "./helpers";

function getBilling(state = initialRequestState(), action) {
  return handleRequest(
    BillingTypes.GET_BILLING_REQUEST,
    BillingTypes.GET_BILLING_SUCCESS,
    BillingTypes.GET_BILLING_FAIL,
    state,
    action
  );
}

export default combineReducers({
  getBilling,
});
