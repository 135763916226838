import { combineReducers } from 'redux';
import { ImageTypes } from '../../action_types';

import { handleRequest, initialRequestState } from './helpers';

function getIdImage(state = initialRequestState(), action) {
  return handleRequest(
    ImageTypes.GET_ID_IMAGE_REQUEST,
    ImageTypes.GET_ID_IMAGE_SUCCESS,
    ImageTypes.GET_ID_IMAGE_FAIL,
    state,
    action
  );
}

function getSelfieImage(state = initialRequestState(), action) {
  return handleRequest(
    ImageTypes.GET_SELFIE_IMAGE_REQUEST,
    ImageTypes.GET_SELFIE_IMAGE_SUCCESS,
    ImageTypes.GET_SELFIE_IMAGE_FAIL,
    state,
    action
  );
}

function getBlockImage(state = initialRequestState(), action) {
  return handleRequest(
    ImageTypes.GET_BLOCK_IMAGE_REQUEST,
    ImageTypes.GET_BLOCK_IMAGE_SUCCESS,
    ImageTypes.GET_BLOCK_IMAGE_FAIL,
    state,
    action
  );
}

export default combineReducers({
  getIdImage,
  getSelfieImage,
  getBlockImage,
});
