import { combineReducers } from "redux";
import { DeviceTypes } from "../../action_types";

import { handleRequest, initialRequestState } from "./helpers";

function getDevices(state = initialRequestState(), action) {
  return handleRequest(
    DeviceTypes.GET_DEVICES_REQUEST,
    DeviceTypes.GET_DEVICES_SUCCESS,
    DeviceTypes.GET_DEVICES_FAIL,
    state,
    action
  );
}

function acceptDeviceConnection(state = initialRequestState(), action) {
  return handleRequest(
    DeviceTypes.ACCEPT_DEVICE_CONNECTION_REQUEST,
    DeviceTypes.ACCEPT_DEVICE_CONNECTION_SUCCESS,
    DeviceTypes.ACCEPT_DEVICE_CONNECTION_FAIL,
    state,
    action
  );
}

function removeDeviceConnection(state = initialRequestState(), action) {
  return handleRequest(
    DeviceTypes.REMOVE_DEVICE_CONNECTION_REQUEST,
    DeviceTypes.REMOVE_DEVICE_CONNECTION_SUCCESS,
    DeviceTypes.REMOVE_DEVICE_CONNECTION_FAIL,
    state,
    action
  );
}

export default combineReducers({
  getDevices,
  acceptDeviceConnection,
  removeDeviceConnection,
});
