export default {
  GET_QUESTIONS_REQUEST: "GET_QUESTIONS_REQUEST",
  GET_QUESTIONS_SUCCESS: "GET_QUESTIONS_SUCCESS",
  GET_QUESTIONS_FAIL: "GET_QUESTIONS_FAIL",

  UPDATE_QUESTION_REQUEST: "UPDATE_QUESTION_REQUEST",
  UPDATE_QUESTION_SUCCESS: "UPDATE_QUESTION_SUCCESS",
  UPDATE_QUESTION_FAIL: "UPDATE_QUESTION_FAIL",

  CREATE_QUESTION_REQUEST: "CREATE_QUESTION_REQUEST",
  CREATE_QUESTION_SUCCESS: "CREATE_QUESTION_SUCCESS",
  CREATE_QUESTION_FAIL: "CREATE_QUESTION_FAIL",

  DELETE_QUESTION_REQUEST: "DELETE_QUESTION_REQUEST",
  DELETE_QUESTION_SUCCESS: "DELETE_QUESTION_SUCCESS",
  DELETE_QUESTION_FAIL: "DELETE_QUESTION_FAIL",
};
